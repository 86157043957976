import "./number-tile.scss";
import React, { useState } from "react";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import { decimalDivider } from "../../functions/decimalDivider";

const NumberTile = ({ icon, number, text, color, suffix }) => {
   const [play, setPlay] = useState(false);
   const [countFinished, setCountFinished] = useState(false);
   return (
      <>
         <Waypoint onEnter={(e) => setPlay(true)} />
         <div className="number-tile">
            <img src={icon} alt="Ikona" className="number-tile__icon" />
            <div className="number-tile__number" style={{ color: color }}>
               {play ? (
                  <CountUp
                     end={number}
                     duration={1.3}
                     onEnd={() => setCountFinished(true)}
                     formattingFn={
                        countFinished
                           ? () => decimalDivider(number, 2, suffix)
                           : null
                     }
                  />
               ) : null}
            </div>
            <div
               className="number-tile__text"
               dangerouslySetInnerHTML={{ __html: text }}
            />
         </div>
      </>
   );
};

export default NumberTile;
