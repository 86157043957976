import "styles/pages/page-home.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import ControlledSlider from "components/ControlledSlider";
import NewsTile from "components/NewsTile";
import { Link, graphql } from "gatsby";
import PhotoTile from "../components/PhotoTile";
import ProjectBanner from "../components/ProjectBanner";
import NumberTile from "../components/NumberTile";
import CollapseTile from "../components/CollapseTile";
import VotesMap from "../components/VotesMap";

const HomePage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const home = pageContext.data;
   const operators = data.allWpOperator.nodes;
   const mapData = data.allWpVote.nodes;
   const listItems = data.allWpPost.nodes;

   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <section className="home-hero">
            <div className="container">
               <ControlledSlider assets={home.heroSlideshow} variant="hero" />
            </div>
         </section>
         <section className="home-about">
            <div className="container">
               <SectionTitle title={home.aboutTitle} />
               <div className="row justify-content-center">
                  <div className="col-md-10">
                     <div
                        className="home-about__text"
                        dangerouslySetInnerHTML={{ __html: home.aboutText }}
                     />
                  </div>
               </div>
            </div>
         </section>
         <section className="home-news">
            <SectionTitle title="Aktualności" />
            <div className="container">
               {listItems.length > 0 && (
                  <>
                     <div className="row justify-content-center">
                        {listItems.map((item, index) => (
                           <div className="col-lg-4 col-md-6" key={index}>
                              <NewsTile
                                 title={item.title}
                                 thumbnail={
                                    item.featuredImage
                                       ? item.featuredImage.node
                                       : ""
                                 }
                                 desc={item.singleNews.desc}
                                 link={item.uri}
                                 date={item.date}
                                 category={item.categories.nodes[0].name}
                                 categoryColor={
                                    item.categories.nodes[0].newsCategory
                                       .categoryColor
                                 }
                                 isNews
                              />
                           </div>
                        ))}
                     </div>
                     <div className="center-element home-news__button-container">
                        <Link to="/aktualnosci/" className="button">
                           Przeglądaj więcej
                        </Link>
                     </div>
                  </>
               )}
               <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                     <PhotoTile
                        title="Kto może wziąć udział w konkursie?"
                        link="/dla-kogo"
                        color="#2E5077"
                        thumbnail={home.imgThumbnail1?.sourceUrl}
                        fontSize={1.18}
                        thumbnailHeight={190}
                     />
                  </div>
                  <div className="col-lg-4 col-md-6">
                     <PhotoTile
                        title="Jakie działania finansujemy?"
                        link="/rodzaje-wspieranych-dzialan"
                        color="#9B1544"
                        thumbnail={home.imgThumbnail2?.sourceUrl}
                        fontSize={1.18}
                        thumbnailHeight={190}
                     />
                  </div>
                  <div className="col-lg-4 col-md-6">
                     <PhotoTile
                        title="Co trzeba zrobić, aby zrealizować projekty?"
                        link="/jak-zlozyc-wniosek"
                        color="#00AAAF"
                        thumbnail={home.imgThumbnail3?.sourceUrl}
                        fontSize={1.18}
                        thumbnailHeight={190}
                     />
                  </div>
               </div>
               <div className="home-news__banner-wrapper">
                  <ProjectBanner
                     title="Zgłoś swój projekt"
                     link="https://www.witkac.pl/Account/Login"
                     btnText="Przejdź do generatora"
                  />
               </div>
            </div>
         </section>
         <section className="home-map">
            <div className="container">
               <SectionTitle title="Mapa realizowanych inicjatyw" />
               <VotesMap data={mapData} />
            </div>
         </section>
         <section className="home-numbers">
            <div className="container">
               <SectionTitle title="Liczby związane z programem" />
               <div className="row">
                  {home.numberColumns.map((item, index) => {
                     return (
                        <div
                           className={`col-md-${Math.ceil(
                              12 / home.numberColumns.length
                           )} home-numbers__col`}
                           key={index}
                        >
                           <NumberTile
                              number={item.amount ? item.amount : "0"}
                              icon={require("assets/icons/hands.svg").default}
                              text={item.caption}
                              color={item.color}
                              suffix={index === 2 ? " PLN" : ""}
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
         </section>
         <section className="home-operators">
            <div className="container">
               <SectionTitle title="Operatorzy małopolska lokalnie" />
               <div className="row">
                  {operators.map((obj, index) => {
                     const item = obj.cptOperators;
                     return (
                        <div
                           className="col-md-6 home-operators__tile"
                           key={index}
                        >
                           <CollapseTile
                              plain
                              logo={item.logo}
                              name={item.name}
                              subtitle={item.address}
                              webpage={item.url}
                              email={item.email}
                              phones={item.phones}
                              activityRegion={item.activityRegion}
                              staff={item.staff}
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
         </section>
         <section className="home-projects">
            <div className="container">
               <SectionTitle title={home.projectTitle} />
               <ControlledSlider
                  assets={home.projectLogos}
                  variant="projects"
               />
            </div>
         </section>
         <section className="home-partners">
            <div className="container">
               <SectionTitle title={home.partnersTitle} />
               <div className="row align-items-center justify-content-center">
                  {home.partnersLogo.map((item, index) => {
                     return (
                        <div
                           className={`col-md-${Math.floor(
                              6 / home.partnersLogo.length
                           )} home-partners__col home-partners__col--divider`}
                           key={index}
                        >
                           <img
                              src={item.sourceUrl}
                              alt={item.altText}
                              className="img-fluid home-partners__logo"
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   query {
      allWpOperator(sort: { fields: cptOperators___name, order: ASC }) {
         nodes {
            cptOperators {
               logo {
                  sourceUrl
                  altText
               }
               activityRegion
               address
               email
               phones {
                  phone
               }
               name
               url
               staff {
                  name
                  group {
                     image {
                        sourceUrl
                     }
                     email
                     name
                     phone
                     url
                  }
               }
            }
         }
      }
      allWpVote {
         nodes {
            singleVotePage {
               mapLat
               mapLong
               mapRealisation
               mapSubregion
               shortDesc
               isShownOnMap
            }
            title
            uri
         }
      }
      allWpPost(limit: 3, sort: { fields: date, order: DESC }) {
         nodes {
            title
            uri
            id
            slug
            date(formatString: "DD.MM.y")
            featuredImage {
               node {
                  sourceUrl
               }
            }
            singleNews {
               desc
            }
            categories {
               nodes {
                  name
                  newsCategory {
                     categoryColor
                  }
                  slug
               }
            }
         }
      }
   }
`;

export default HomePage;
