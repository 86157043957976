import "./controlled-slider.scss";

import React, { useRef, useState } from "react";
import Slider from "react-slick";

const ControlledSlider = ({ assets, variant }) => {
   const [play, setPlay] = useState(true);
   const sliderRef = useRef(null);

   const sliderOptions = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: false,
      draggable: false,
   };

   const projectsSlider = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: false,
      draggable: true,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };

   return (
      <div className={`controlled-slider controlled-slider--${variant}`}>
         {variant === "hero" && (
            <Slider ref={sliderRef} {...sliderOptions}>
               {assets.map((item, index) => {
                  return (
                     <div
                        className="controlled-slider__single-slide controlled-slider__single-slide--hero"
                        key={index}
                     >
                        <img
                           src={
                              item.image.sourceUrl
                                 ? item.image.sourceUrl
                                 : require("../../assets/images/news-placeholder.jpg")
                                      .default
                           }
                           alt={item.image?.altText}
                           className="controlled-slider__img controlled-slider__img--hero"
                        />
                        <div className="controlled-slider__text controlled-slider__text--hero">
                           {item.text ? item.text : item.title}
                        </div>
                     </div>
                  );
               })}
            </Slider>
         )}
         {variant === "projects" && (
            <Slider ref={sliderRef} {...projectsSlider}>
               {assets.map((item, index) => {
                  return (
                     <div
                        className="controlled-slider__single-slide controlled-slider__single-slide--projects"
                        key={index}
                     >
                        <img
                           src={item.sourceUrl}
                           alt={item?.altText}
                           className="img-fluid controlled-slider__img controlled-slider__img--projects"
                        />
                     </div>
                  );
               })}
            </Slider>
         )}
         <div
            className={`controlled-slider__control-panel controlled-slider__control-panel--${variant}`}
         >
            <button
               type="button"
               onClick={(e) => sliderRef.current.slickPrev()}
               className="controlled-slider__button-prev"
               aria-label="Poprzedni"
            />
            <button
               type="button"
               onClick={(e) =>
                  play
                     ? (sliderRef.current.slickPause(), setPlay(false))
                     : (sliderRef.current.slickPlay(), setPlay(true))
               }
               className={
                  play
                     ? "controlled-slider__button-pause"
                     : "controlled-slider__button-play"
               }
               aria-label={play ? "Pause" : "Odtwórz"}
            />
            <button
               type="button"
               onClick={(e) => sliderRef.current.slickNext()}
               className="controlled-slider__button-next"
               aria-label="Następny"
            />
         </div>
      </div>
   );
};

export default ControlledSlider;
