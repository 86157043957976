// Returns rounded num value with decimal separtor and float based on decimal amount

export const decimalDivider = (num, decimals, suffix) => {
   num = Math.round(num);
   if (num > 1000 && num < 1000000) {
      num = num / 1000;
      num = num * 10 ** decimals;
      num = Math.round(num);
      num = num / 10 ** decimals;
      num = num.toFixed(2).replace(".", ",");
      return `${num} tys. ${suffix}`;
   } else if (num > 1000000 && num < 1000000000) {
      num = num / 1000000;
      num = num * 10 ** decimals;
      num = Math.round(num);
      num = num / 10 ** decimals;
      num = num.toFixed(2).replace(".", ",");
      return `${num} mln ${suffix}`;
   } else if (num > 1000000000 && num < 1000000000000) {
      num = num / 1000000000;
      num = num * 10 ** decimals;
      num = Math.round(num);
      num = num / 10 ** decimals;
      num = num.toFixed(2).replace(".", ",");
      return `${num} mld ${suffix}`;
   } else if (num > 1000000000000 && num < 1000000000000) {
      num = num / 1000000000000;
      num = num * 10 ** decimals;
      num = Math.round(num);
      num = num / 10 ** decimals;
      num = num.toFixed(2).replace(".", ",");
      return `${num} bln ${suffix}`;
   } else {
      return num;
   }
};
